<template>
  <!-- 合同物流 - 接受合作计划 -->
  <div class="createPlanPage" v-loading="flag.loading">
    <div class="facilityBox">
      <!-- tab切换 -->
      <el-tabs v-model="flag.activeTab" @tab-click="changeTabs">
        <el-tab-pane v-for="item in tabsList" :key="item.index" :label="item.name" :name="item.index"></el-tab-pane>
      </el-tabs>

      <!-- 搜索表单 -->
      <el-form ref="searchForm" inline :model="searchForm">
        <el-form-item label="运单号" prop="orderID">
          <el-input v-model="searchForm.orderID" placeholder="请输入运单号" style="width: 160px" clearable></el-input>
        </el-form-item>
        <el-form-item label="货主名称" prop="ownerName">
          <el-input v-model="searchForm.ownerName" placeholder="请输入货主名称" style="width: 160px" clearable></el-input>
        </el-form-item>
        <el-form-item v-if="flag.activeTab > 2" label="司机名称" prop="driverName">
          <el-input v-model="searchForm.driverName" placeholder="请输入司机名称" style="width: 160px" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <div style="display: flex">
            <el-dropdown @command="handleCommand" trigger="click" placement="bottom">
              <span class="el-dropdown-link">
                <div style="
                    width: 108px;
                    text-align: right;
                    padding-right: 12px;
                    color: #606266;
                    cursor: pointer;
                    white-space: nowrap;
                  ">
                  {{ timeSelectTxt
                  }}<i style="margin-left: 2px" class="el-icon-arrow-down">：</i>
                </div>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="item" v-for="item in timeTypeList" :key="item.name">{{ item.name }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-date-picker v-model="searchForm[timeSelect]" type="daterange" start-placeholder="请选择开始时间"
              end-placeholder="请选择结束时间" value-format="yyyy-MM-dd" @change="changeTime" style="width: 250px">
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item label="起始地" prop="startAdressRoad">
          <el-input v-model="searchForm.startAdressRoad" placeholder="请输入起始地" style="width: 160px" clearable></el-input>
        </el-form-item>
        <el-form-item label="到达地" prop="endAddressRoad">
          <el-input v-model="searchForm.endAddressRoad" placeholder="请输入到达地" style="width: 160px" clearable></el-input>
        </el-form-item>

        <el-button type="primary" icon="el-icon-zoom-in" @click="(pagination.page = 1), search()">搜索</el-button>
        <el-button icon="el-icon-delete" @click="clearInput(), search()">清空</el-button>
      </el-form>

      <!-- 表格信息 -->
      <el-table :data="pagination.list" v-loading="pagination.loading"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }" :key="flag.activeTab">
        <el-table-column fixed="left" align="center" prop="index" label="序号" width="50">
        </el-table-column>
        <el-table-column align="center" prop="OrderID" label="运单号" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="OwnerName" label="货主单位" width="150" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="LoaderName" label="装货人" width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-link class="clickTxt" @click="orderMoreTime(scope.row.OrderID, 'name')">
              {{ scope.row.LoaderName }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="SignUserName" label="收货人" width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-link class="clickTxt" @click="orderMoreTime(scope.row.OrderID, 'name')">
              {{ scope.row.SignUserName }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="LoadingStartTime" label="装货时间" width="150" show-overflow-tooltip>
        </el-table-column>
        <el-table-column v-if="flag.activeTab == 5" align="center" prop="PlanStartDatetime" label="卸货时间" width="200"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="StartTime" label="起始时间" width="150" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="EndTime" label="到达时间" width="150" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="StartAdressRoad" label="起始地" width="210" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.StartAdressRoad }}</span>
            <!-- <span v-if="scope.row.Status < 11">
              {{ scope.row.StartAdressRoad }}
            </span>
            <el-link v-else class="clickTxt" @click="openMap(scope.row)">
              {{ scope.row.StartAdressRoad }}
            </el-link> -->
          </template>
        </el-table-column>
        <el-table-column align="center" prop="EndAddressRoad" label="到达地" width="210" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.EndAddressRoad }}</span>
            <!-- <span v-if="scope.row.Status < 11">{{ scope.row.EndAddressRoad }}</span>
            <el-link v-else class="clickTxt" @click="openMap(scope.row)">
              {{ scope.row.EndAddressRoad }}
            </el-link> -->
          </template>
        </el-table-column>
        <el-table-column align="center" prop="GoodsName" label="货物名称" width="150" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-link class="clickTxt" @click="orderMoreTime(scope.row.OrderID, 'goods')">
              {{ scope.row.GoodsName }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="CarrierPrice" label="运费" width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.CarrierPrice | formatMoney }}
            </div>
          </template>
        </el-table-column>

        <el-table-column v-if="flag.activeTab == 1" align="center" prop="CreateTime" label="申请时间" width="150"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column v-if="flag.activeTab != 1" align="center" prop="DriverName" label="司机" width="100"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.Status == 5 || scope.row.Status == 1">{{
              scope.row.DriverName
            }}</span>
            <el-link v-else class="clickTxt" @click="
            getPersonInfo(
              scope.row.DriverId,
              scope.row.OrderID,
              scope.row.OwnerUserId
            )
              ">
              {{ scope.row.DriverName }}
            </el-link>
          </template>
        </el-table-column>

        <el-table-column v-if="flag.activeTab != 1 && flag.activeTab != 2" align="center" prop="CarNumber" label="车辆"
          width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-link :class="[
              { clickTxt: scope.row.CarNumber != '待指派' },
              { linkActive: scope.row.CarNumber == '待指派' },
            ]" @click="showVehicleInfo(scope.row.CarNumber)"
              :underline="scope.row.CarNumber != '待指派' ? true : false">
              {{ scope.row.CarNumber }}
            </el-link>
          </template>
        </el-table-column>

        <el-table-column align="center" v-if="flag.activeTab != 1" prop="TyDatetime"
          :label="flag.activeTab == 5 ? '完成时间' : '同意时间'" width="150" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ flag.activeTab == 5 ? scope.row.StatusChangeTime : scope.row.TyDatetime }}
          </template>
        </el-table-column>

        <el-table-column v-if="flag.activeTab == 2 || flag.activeTab >= 5" align="center" prop="StatusName" label="运单状态"
          width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag :color="setOrderStatus(scope.row.StatusName).bg" :style="[
              { color: setOrderStatus(scope.row.StatusName).text },
              { border: '0px' },
            ]">
              {{ setOrderStatus(scope.row.StatusName).name }}
            </el-tag>

            <el-popover placement="left" title="原因" trigger="hover" :content="scope.row.StatusChangeMsg">
              <i v-show="scope.row.StatusChangeMsg" class="el-icon-question"
                style="font-size: 16px; color: #ccc; cursor: pointer; margin-left: 4px" slot="reference"></i>
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column v-if="flag.activeTab == 6" align="center" prop="StatusChangeTime" label="状态变更时间" width="150"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column v-if="flag.activeTab != 6" fixed="right" align="center" prop="" label="操作" width="200">


          <template slot-scope="scope">

            <el-button v-if="scope.row.Status >= 11" @click="$refs.htwlPdfPrint.init(scope.row.OrderID)" type="text"
              size="small">
              运费
            </el-button>
            <el-button size="mini" type="text" @click="accept(scope.row.OrderID)" v-if="flag.activeTab == 1">同意
            </el-button>
            <el-button size="mini" type="text" style="color: #f56c6c" @click="reject(scope.row.OrderID, '拒绝')"
              v-if="flag.activeTab == 1">拒绝</el-button>
            <el-button size="mini" type="text" style="color: #0f5fff" @click="
              getPersonInfo(
                scope.row.DriverId,
                scope.row.OrderID,
                scope.row.OwnerUserId
              )
              " v-if="flag.activeTab == 2">指派司机</el-button>
            <el-button size="mini" type="text" style="color: #f56c6c" @click="reject(scope.row.OrderID, '拒绝承运')"
              v-if="flag.activeTab == 2">拒绝承运</el-button>
            <el-button size="mini" type="text" style="color: #999999" @click="reject(scope.row.OrderID, '取消指派司机')"
              v-if="flag.activeTab == 3">取消指派</el-button>
            <el-button size="mini" type="text" v-if="flag.activeTab == 3" style="color: #f56c6c"
              @click="reject(scope.row.OrderID, '放弃承运')">放弃承运</el-button>
            <el-button size="mini" type="text" @click="detailOrder(scope.row)"
              v-if="flag.activeTab == 4 || flag.activeTab == 5">查看详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination style="margin-top: 16px" background @current-change="waybillPaginationChange"
        :current-page.sync="pagination.page" :page-size="pagination.pagesize" :total="pagination.total"
        layout="total, prev, pager, next, jumper">
      </el-pagination>

      <!--人员信息弹框-->
      <el-dialog top="13vh" class="dialog" :visible.sync="flag.personDialog" width="1300px" :close-on-click-modal="false">
        <span slot="title">人员信息</span>
        <DriverInfo :driverInfo="driverInfo" />
      </el-dialog>

      <!--显示车辆信息弹框-->
      <el-dialog top="13vh" class="dialog" :visible.sync="flag.vehicleDialog" width="1300px"
        :close-on-click-modal="false">
        <span slot="title">车辆信息</span>
        <VehicleInfo :vehicleInfo="vehicleInfo" />
      </el-dialog>

      <!--查看轨迹-->
      <el-dialog width="800px" :visible.sync="flag.traceDialog" append-to-body class="dialog"
        :close-on-click-modal="false">
        <span slot="title">查看地图</span>
        <!-- 地图组件 -->
        <TXmap style="height: 600px" v-if="flag.traceDialog" ref="map" :list="mapList">
        </TXmap>
      </el-dialog>

      <!-- 司机列表dialog -->
      <el-dialog :title="chooseDriverPage == 1
          ? '指派司机'
          : chooseDriverPage == 2
            ? '关联车辆'
            : '添加收款人'
        " :visible.sync="flag.driverListDialog" width="1200px" class="appointDriverAndCar"
        :close-on-click-modal="false">
        <AppointDriverAndCar @upChooseDriverPage="upChooseDriverPage" ref="appointDriverAndCar"></AppointDriverAndCar>
        <!-- 底部按钮 -->
        <span slot="footer">
          <el-button size="mini" type="primary" @click="flag.driverListDialog = false" style="margin-top: 15px"
            v-show="chooseDriverPage == 1 || chooseDriverPage == 3">取消</el-button>
          <el-button size="mini" type="primary" style="margin-top: 15px" v-show="chooseDriverPage == 2"
            @click="(chooseDriverPage = 1), ($refs.appointDriverAndCar.currentInfo = 1)">上一页</el-button>
          <el-button size="mini" type="primary" @click="confirmPeople">确定</el-button>
        </span>
      </el-dialog>

      <!-- 人员、商品信息查看dialog -->
      <el-dialog :title="otherDetail == 'name'
          ? '人员信息查看'
          : otherDetail == 'goods'
            ? '货品信息查看'
            : '更多时间查看'
        " :visible.sync="flag.peoAndGoodsDialog" width="700px" class="peoAndGoodsDialog" :close-on-click-modal="false">
        <div class="detail-msg">
          <el-form inline label-position="top" label-width="200px" :key="1">
            <el-form-item v-for="item in getOtherList" :key="item.name" :label="item.text">
              <el-input disabled class="inputWidth" :value="item.time">
                <template v-if="item.Company" slot="append">{{ item.Company }}</template>
              </el-input>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>

      <!-- 订单完成 - 车辆轨迹 -->
      <el-dialog :visible.sync="flag.vehicleTrack" width="1200px" destroy-on-close custom-class="customClass"
        :close-on-click-modal="false">
        <vehicleTrack ref="vehicleTrack" :currentOrder="currentOrder" :orderDetail="currentOrderObj"></vehicleTrack>
      </el-dialog>
      <!--打印运费-->
      <htwlPdfPrint ref="htwlPdfPrint"></htwlPdfPrint>
    </div>
  </div>
</template>

<script>
import htwlPdfPrint from "@/components/businessCmpt/htwlPdfPrint";
import ContractWaybillTrace from "@/components/commonCmpt/contractWaybillTrace";
import {
  GetFacilitatorZlOrderHtw,
  GetOrderTimeAsync,
  PutOrderStatus,
  SetProviDerdriver,
  SetCancelOrderProviDerdriver,
  SetCancelProviDerdriver,
  GetHtwOrdersFirst,
} from "@/api/contractLogistics/createPlan";
import { getDriverDetails, getCarInfo, getMapPath } from "@/api/common/common.js";

import VehicleInfo from "@/components/businessCmpt/vehicleInfo";
import DriverInfo from "@/components/businessCmpt/driverInfo";
import TXmap from "@/components/commonCmpt/TXmap";
import AppointDriverAndCar from "@/components/businessCmpt/appointDriverAndCar.vue";
import { setOrderStatus } from "@/utils/setHTWLstatus";
import vehicleTrack from "@/components/commonCmpt/vehicleTrack";
import { formatMoney } from "@/utils/filter.js";

export default {
  data() {
    return {
      flag: {
        activeTab: "1",
        driverListDialog: false, //选择司机弹窗
        peoAndGoodsDialog: false, // 人员、商品信息
        traceDialog: false, // 查看轨迹组件
        loading: false, // 打开弹框
        personDialog: false, // 人员信息弹框
        vehicleDialog: false, // 车辆信息弹框
        vehicleTrack: false, // 车辆轨迹
      },
      status: ["待服务商确认"], // 运单状态
      searchForm: {
        orderID: "", // 运单号
        ownerName: "", //货主名称
        driverName: "", // 司机名称
        startAdressRoad: "", // 起始地
        endAddressRoad: "", // 到达地
        createTime: [], // 申请时间
        startTime: [], // 起始时间
        endTime: [], // 到达时间
        loadingStartTime: [], // 装货开始时间
        loadingEndTime: [], // 装货结束时间
      },
      // 运单状态tab
      tabsList: [
        { index: "1", name: "待处理", type: ["待服务商确认"] },
        {
          index: "2",
          name: "已同意",
          type: ["服务商确认", "司机已拒绝", "司机已取消"],
        },
        {
          index: "3",
          name: "待运输",
          type: ["待司机确认", "司机待指派", "司机已确认", "司机待装货"],
        },
        { index: "4", name: "运输中", type: ["运输中"] },
        { index: "5", name: "已完成", type: ["已完成", "已发往运单库"] },
        { index: "6", name: "拒绝/取消", type: ["服务商拒绝", "货主已取消"] },
      ],
      // 分页
      pagination: {
        page: 1,
        pagesize: 10,
        list: [],
        total: 0,
        loading: false,
      },
      chooseDriverPage: 1, // 选择司机还是选择车辆， 1 司机， 2车辆， 3 代收人
      currentOrderId: "", // 当前操作的订单编号
      // 时间查询条件
      timeTypeList: [
        { name: "申请时间", value: "createTime" },
        { name: "装货开始时间", value: "loadingStartTime" },
        { name: "装货结束时间", value: "loadingEndTime" },
        { name: "起始时间", value: "startTime" },
        { name: "到达时间", value: "endTime" },
      ],
      timeSelect: "createTime", // 选择的时间类型
      timeSelectTxt: "申请时间", // 选择的时间类型名称
      otherDetail: "", // 详情类型
      driverInfo: {}, // 司机 收款人信息
      vehicleInfo: {}, // 车辆信息
      // 更多时间
      otherTimeList: [
        { name: "LoadingStartTime", text: "装货开始时间" },
        { name: "LoadingEndTime", text: "装货结束时间" },
        { name: "StartTime", text: "起始时间" },
        { name: "EndTime", text: "到达时间" },
        { name: "CreateTime", text: "创建时间" },
        { name: "StatusChangeTime", text: "状态变更时间" },
      ],
      // 跟多人员
      otherPeoList: [
        { name: "PayeeName", text: "收款人" },
        { name: "PayeePhone", text: "手机号" },
        { name: "LoaderName", text: "装货人" },
        { name: "LoaderPhone", text: "手机号" },
        { name: "SignUserName", text: "收货人" },
        { name: "SignUserPhone", text: "手机号" },
      ],
      // 跟多商品信息
      otherGoodsList: [
        { name: "GoodsName", text: "货品名称" },
        { name: "LoadCount", text: "货品数量" },
        { name: "GoodsUnit", text: "货品单位" },
        { name: "GoodsTotalWeight", text: "货品总重", Company: "吨" },
        { name: "GoodsValue", text: "货品价值", Company: "元" },
        { name: "CarrierPrice", text: "运费", Company: "元" },
      ],
      currentOrder: "", // 查看详情,当前选中的订单单号
      currentOrderObj: {}, // 查看详情,当前选中的订单信息
    };
  },
  created() {
    this.search();
  },
  components: {
    htwlPdfPrint,
    ContractWaybillTrace,
    DriverInfo,
    VehicleInfo,
    TXmap,
    AppointDriverAndCar,
    vehicleTrack,
  },
  computed: {
    // 更多详情列表
    getOtherList() {
      const type = {
        time: this.otherTimeList,
        goods: this.otherGoodsList,
        name: this.otherPeoList,
      };
      return type[this.otherDetail];
    },
  },
  methods: {
    setOrderStatus,

    formatMoney,

    // 切换tab页
    changeTabs(e) {
      this.clearInput();
      this.pagination.page = 1;

      let res = this.tabsList.find((item) => item.name == e.label);
      if (res) (this.status = res.type), this.search();
    },

    // 清除时间选择
    clearInput() {
      this.$refs["searchForm"].resetFields();
      this.searchForm = {
        createTime: [], // 申请时间
        startTime: [], // 起始时间
        endTime: [], // 到达时间
        loadingStartTime: [], // 装货开始时间
        loadingEndTime: [], // 装货结束时间
      };
      this.timeSelect = "createTime"; // 选择的时间类型
      this.timeSelectTxt = "申请时间"; // 选择的时间类型名称
    },

    // 清除时间, 设置为空
    changeTime(e) {
      if (e == null) {
        this.$nextTick(() => {
          this.searchForm[this.timeSelect] = [];
        });
      }
    },

    // 服务商获取合同物流列表
    search() {
      this.pagination.loading = true;
      let params = {
        ...this.searchForm,
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        status: this.status,
      };

      GetFacilitatorZlOrderHtw(params)
        .then((res) => {
          this.pagination.list = res.data.DataList.map((item, index) => {
            item.index =
              (this.pagination.page - 1) * this.pagination.pagesize + index + 1;

            // 已同意tab页， 如果是 司机已拒绝 清除司机信息
            if (
              this.flag.activeTab == 2 &&
              (item.StatusName == "司机已拒绝" || item.StatusName == "司机已取消")
            ) {
              item.DriverName = "待指派";
              item.DriverId = null;
            }

            return item;
          });
          this.pagination.total = res.data.TotalCount;
        })
        .finally(() => {
          this.pagination.loading = false;
        });
    },

    //运单分页改变
    waybillPaginationChange(e) {
      this.pagination.page = e;
      this.search();
    },

    // 选择时间下拉菜单
    handleCommand(e) {
      this.timeTypeList.forEach((item) => {
        this.searchForm[item.value] = [];
      });

      this.timeSelectTxt = e.name;
      this.timeSelect = e.value;
    },

    // 显示更多时间
    orderMoreTime(id, type) {
      this.otherDetail = type;
      GetOrderTimeAsync(id, type).then((res) => {
        let { data } = res;
        let list =
          type == "time"
            ? "otherTimeList"
            : type == "goods"
              ? "otherGoodsList"
              : "otherPeoList";

        this[list].forEach((item) => {
          item.time = data[item.name];
        });

        this.flag.peoAndGoodsDialog = true;
      });
    },

    // 查询人员信息
    getPersonInfo(UserID, orderID, ownerId) {
      if (!UserID) {
        // 如果当前订单处于 待运输 之前的步骤, 会弹出选择司机框
        this.openDriverDialog(1, orderID, ownerId);
        return;
      }
      this.flag.loading = true;
      let data = {};
      getDriverDetails({ UserID })
        .then((res) => {
          data = Object.assign({}, res.data.driver_baseInfo, res.data.driver_confirmInfo);
          this.driverInfo = data;
          this.flag.personDialog = true;
          this.flag.loading = false;
        })
        .finally(() => {
          this.flag.loading = false;
        });
    },

    // 获取车辆信息
    showVehicleInfo(CarNumber) {
      if (CarNumber.includes("指派")) return;
      this.flag.loading = true;
      let getCarInfoed = false; //是否拿到了车子证件照
      let params = {}; // 车辆信息
      let params2 = {
        CarNumber,
      };
      getCarInfo(params2)
        .then((res) => {
          this.vehicleInfo = Object.assign(
            params,
            res.data.vehicle_baseInfo,
            res.data.vehicle_confirmInfo
          );
          getCarInfoed = true;
        })
        .catch((err) => { })
        .finally(() => {
          if (getCarInfoed) {
            this.flag.vehicleDialog = true;
          }
          this.flag.loading = false;
        });
    },

    // 打开地图
    openMap(item) {
      if (item.Status < 11) {
        this.$message.info("该订单未运输，暂无轨迹");
        return;
      }
      this.flag.loading = true;
      let params = {
        searchWord: item.CarNumber,
        stDate: item.LoadingStartTime,
        edDate: item.LoadingEndTime,
        coordType: 2,
        wayBillId: item.OrderID,
      };
      getMapPath({ json: JSON.stringify(params) })
        .then((res) => {
          this.mapList = res.Track;
          this.flag.traceDialog = true;
        })
        .finally(() => {
          this.flag.loading = false;
        });
    },

    // 选择司机还是选择车辆
    upChooseDriverPage(data) {
      this.chooseDriverPage = data;
    },

    // 打开指派司机/车辆dialog
    openDriverDialog(statys, orderID, ownerId) {
      if (this.currentOrderId != orderID) {
        this.clearDialogInfo();
      }

      this.currentOrderId = orderID;
      this.chooseDriverPage = statys;
      this.flag.driverListDialog = true;
      this.$nextTick(() => {
        this.$refs.appointDriverAndCar.currentInfo = 1; // 添加司机
        this.$refs.appointDriverAndCar.issupplier = true; // 确定是服务商
        this.$refs.appointDriverAndCar.driverSearch.ownerId = ownerId; // 确定是服务商
        this.$refs.appointDriverAndCar.getDriverList();
      });
    },

    // 清除弹框数据
    clearDialogInfo() {
      if (this.$refs.appointDriverAndCar) {
        this.$refs.appointDriverAndCar.initialization();
      }
    },

    // 确认司机， 提交运输
    confirmPeople() {
      let { driverInfo, vehicleInfo } = this.$refs.appointDriverAndCar;

      if (!driverInfo.UserID) {
        this.$message.warning("请先指派司机后再点击确定");
        return;
      }

      SetProviDerdriver(
        this.currentOrderId,
        driverInfo.UserID,
        vehicleInfo?.CarNumber || " "
      )
        .then((res) => {
          this.$message.success("指派司机成功");
          this.search();
          this.flag.driverListDialog = false;
        })
        .catch((err) => { });
    },

    // 同意订单
    accept(orderID) {
      this.$confirm("是否确定同意该运单", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        center: true,
      })
        .then(() => {
          PutOrderStatus({
            orderId: orderID,
            htwLorderStatusId: "服务商确认",
          }).then((res) => {
            this.$message.success("操作成功");
            // this.pagination.page = 1;
            // this.$nextTick(() => {
            //   this.flag.activeTab = "2";
            //   this.changeTabs({ label: "已同意" });
            // });
            this.search();
          });
        })
        .catch(() => { });
    },

    // 拒绝订单
    reject(orderID, type) {
      this.$prompt("", `是否${type}该运单`, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        inputPlaceholder: `请输入${type}原因`,
        center: true,
        inputValidator(value) {
          if (!value) {
            return `请输入${type}原因`;
          } else if (value && value.length > 200) {
            return "请将原因控制在300个字符以内";
          }
        },
      })
        .then((e) => {
          const ajaxFn =
            type == "拒绝"
              ? PutOrderStatus
              : type == "取消指派司机"
                ? SetCancelProviDerdriver
                : SetCancelOrderProviDerdriver;
          const htwLorderStatusId = type == "取消指派司机" ? "服务商确认" : "服务商拒绝";
          ajaxFn({
            orderId: orderID,
            htwLorderStatusId,
            msg: e.value,
          }).then((res) => {
            this.$message.success(`${type}成功`);
            // this.pagination.page = 1;
            this.search();
          });
        })
        .catch(() => { });
    },

    // 查看运单详情
    detailOrder(order) {
      this.currentOrder = order.OrderID;

      GetHtwOrdersFirst(order.OrderID).then((res) => {
        this.currentOrderObj = res.data || {};
      });
      this.flag.vehicleTrack = true;

      this.$nextTick(() => {
        this.$refs.vehicleTrack.loading = true;
        // this.$refs.vehicleTrack.GetHtwOrdersFirst(); // 获取订单详情
        this.$refs.vehicleTrack.getClockInList(); // 获取打卡记录
        this.$refs.vehicleTrack.openMap(order); //打开地图
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.clickTxt {
  color: #0f5fff;
}

.linkActive:hover {
  color: #606266 !important; //高亮的颜色
  cursor: default;
}

.detail-msg {
  display: flex;
  flex-wrap: wrap;
  font-size: 16px !important;

  div {
    width: 48%;
    line-height: 40px;
  }
}

.createPlanPage .inputWidth {
  width: 300px !important;

  /deep/ .el-input__inner {
    height: 42px !important;
  }
}

/deep/.el-form--label-top .el-form-item__label {
  padding: 0 !important;
}

.appointDriverAndCar {
  /deep/.el-dialog__body {
    padding: 6px 20px !important;
  }
}
</style>

<style>
.customClass .el-dialog__header,
.customClass .el-dialog__body {
  padding: 0 !important;
}

.customClass .el-dialog__headerbtn {
  z-index: 10000;
  top: 10px;
  right: 25px;
}

.peoAndGoodsDialog .el-dialog__body {
  padding: 0 20px 30px;
}

.peoAndGoodsDialog .el-form--label-top .el-form-item__label {
  padding: 0;
}

.appointDriverAndCar .el-dialog {
  top: -10%;
}

.dialog .el-dialog {
  top: -10%;
}
</style>
